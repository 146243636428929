import React from 'react';
import { Link } from 'gatsby';
import { LINK_TYPE } from '../constants';
import { getLinkType } from '../util/links';

const CTA = ({ to, ...props }) => {
  const isInternal = getLinkType(to) === LINK_TYPE.INTERNAL;

  if (isInternal) {
    return (
      <Link data-token-cta to={to}>
        {props.children}
      </Link>
    );
  } else {
    return (
      <a data-token-cta href={to}>
        {props.children}
      </a>
    );
  }
};

export default CTA;
