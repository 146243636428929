import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { Section } from '../components/containers';
import Cta from '../components/cta';

export default function Page({ data }) {
  const { frontmatter, html } = data.markdownRemark;

  return (
    <Layout template='default'>
      <Seo title={frontmatter.title} />
      <Section>
        <h1>{frontmatter.title}</h1>
      </Section>
      <Section halign='left'>
        <div dangerouslySetInnerHTML={{ __html: html }}></div>
        <Cta to={'/2020/09/two-corners-of-the-iso-globe/'}>Let's Go</Cta>
      </Section>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        template
      }
      html
    }
  }
`;
